import { useForm } from 'react-hook-form'
import _get from 'lodash/get'
import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'

import Card from './PayTypeMessage/card'
import Bank from './PayTypeMessage/bank'
import EmailSentMessage from './email-sent-message'
import { retrieveConfig } from '../../config/config'
import { CurrencySymbol } from '../../utils/currency-symbol'

export default function PaymentSuccess(props) {
    const [config, setConfig] = useState(null)

    useEffect(() => {
        ;(async function loadConfig() {
            const result = await retrieveConfig()
            setConfig(result)
        })()
    }, [])

    const [receiptSent, setReceiptSent] = useState(false)
    const [isEmailError, setIsEmailError] = useState(false)
    const errorClass = 'ring ring-red-300 focus:ring ring-red-300 hover:shadow '
    const generalInputClass = 'w-full py-1 border border-slate-200 rounded-lg px-1 focus:outline-none '
    const hasEmailReceipt = config?.enableEmailReceipt ?? false

    const schema = yup.object().shape({
        email: yup.string().required('Value is required').max(255, 'Value must be no larger then 255 characters long'),
    })
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = async data => {
        axios
            .post(window.env.REACT_APP_DEMO_BACKEND_URL + '/payment/receipt/' + props.chargeId + '/send', {
                receipientEmail: data.email,
            })
            .then(() => {
                setReceiptSent(true)
            })
            .catch(() => {
                setReceiptSent(true)
                setIsEmailError(true)

                window.setTimeout(() => {
                    setReceiptSent(false)
                    setIsEmailError(false)
                    reset()
                }, 5000)
            })
    }

    if (!props.visible) {
        return null
    }
    return (
        <div className="success-box">
            <div className="successDetails">
                <div className="flex flex-col mb-2">
                    <div className="flex-1 w-64 text-left font-bold text-xl ">Receipt Number</div>
                    <div className="flex-1 w-32 text-left">{props.receiptDetails.receiptNo}</div>
                </div>
                <div className="flex flex-col mb-2">
                    <div className="flex-1 w-64 text-left font-bold text-xl ">Payment Date</div>
                    <div className="flex-1 w-32 text-left">{props.receiptDetails.paymentDate}</div>
                </div>
                <div className="flex flex-col mb-2">
                    <div className="flex-1 w-64 text-left font-bold text-xl ">Payment Amount</div>
                    <div className="flex-1 w-32 text-left">
                        <CurrencySymbol isCurrency={true} currencyCode={config.currencyCode || ''} isField={false} />
                        {props.receiptDetails.paymentAmount}
                    </div>
                </div>
                <div className="flex flex-col mb-2">
                    <div className="flex-1 w-64 text-left font-bold text-xl ">Payment Type</div>
                    <div className="flex-1 w-32 text-left">{props.receiptDetails.paymentType}</div>
                </div>
                {props.receiptDetails.paymentType === 'Card' ? (
                    <Card data={props.receiptDetails} />
                ) : (
                    <Bank data={props.receiptDetails} />
                )}
            </div>
            {hasEmailReceipt && (
                <div className="emailReceipt">
                    {receiptSent ? (
                        <EmailSentMessage isError={isEmailError} />
                    ) : (
                        <form
                            className="receipt-form flex flex-2 flex-col bg-gray-100 pt-3 pb-2 pl-4 pr-4 m-4 space-y-2 m-8"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <h4 className="font-semibold text-lg text-blue-900">Email Payment Receipt</h4>

                            <label htmlFor="email" className="mt-3">
                                <p className="font-medium text-slate-700 pb-1">Email Address</p>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className={generalInputClass + (errors.code ? errorClass : '')}
                                    {...register('email')}
                                />
                                {errors.email && <p className="text-red-500 text-sm mt-2">{errors.email.message}</p>}
                            </label>
                            <div className="relative">
                                <input
                                    type="submit"
                                    className="w-full py-2 font-medium text-white bg-red-600 hover:bg-red-500 rounded-lg border-red-500 hover:shadow inline-flex space-x-2 items-center justify-center"
                                    value="Email Receipt"
                                ></input>
                            </div>
                        </form>
                    )}
                </div>
            )}
        </div>
    )
}
