import React from 'react'

export default function Bank(props) {
    return (
        <>
            <div className="flex flex-col mb-2">
                <div className="flex-1 w-64 text-left font-bold text-xl ">Bank Names</div>
                <div className="flex-1 w-32 text-left">{props.data.bankName}</div>
            </div>
        </>
    )
}
